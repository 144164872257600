import Helmet from "react-helmet"
import React from "react"
import ThankYouPage from "@tightrope/typ"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import {redirect} from "@tightrope/lpscripts/redirect"


export default function ThankYou() {
  let options = { delay : 1000};
  let url = "https://recipestab.com/ty/frompdftodoc/";
  redirect(options,url);
  return(
    <>
    <Helmet>
      <style type="type/css">{`


        `}
        </style>
        <title>Thank You for Downloading FromPDFtoDOC</title>
      </Helmet>
    <section>
      <ThankYouPage data={Data} siteData={SiteData}></ThankYouPage>
    </section>
    </>
  )
}
